<!-- 资金明细 -->
<template>
  <div id="UserFunds">
    <van-nav-bar :title="$t('m_mime.zjpx')" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
      <!-- <template #right>
        <i style="color: #000;font-size: 0.4rem;" class="iconfont icon-shaixuanguolv"></i>
      </template> -->
    </van-nav-bar>
    <div class="funds_warp">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('common.nomore')"
        :loading-text="$t('common.loading-text')" :immediate-check="false" @load="onloadInfo">
        <ul class="funds_list">
          <li v-for="(item, index) in list" :key="index">
            <p>
              <strong>{{ item.cate | fometCatePram }}</strong>
              <!-- <em>{{ item.money | privatefg}}</em> -->
              <em>{{ item.money | privateMoney }}</em>
            </p>
            <p>
              <span>{{ item.update_time }}</span><strong>{{ item.type | fometTypePram }}</strong>
            </p>
          </li>
        </ul>
      </van-list>
    </div>
    <!-- 抽屉 -->
    <van-popup v-model="show" position="right" :style="{ height: '100%' }">
      <div class="subnav" style="width: 5.2rem">
        <ul class="nav-wrap">
          <li>
            <p class="tit">时间</p>
            <van-field readonly clickable name="datetimePicker" placeholder="点击选择时间" @click="showPicker = true" />
          </li>
          <li>
            <p class="tit">交易类型</p>
            <div class="f-box">
              <div class="vux-checker-box">
                <div class="vux-checker-item vux-tap-active f-item active">
                  全部
                </div>
                <div class="vux-checker-item vux-tap-active f-item">收入</div>
                <div class="vux-checker-item vux-tap-active f-item">支出</div>
              </div>
            </div>
          </li>
          <li>
            <p class="tit">交易类别</p>
            <div class="f-box">
              <div class="vux-checker-box">
                <div class="vux-checker-item vux-tap-active f-item active">
                  全部
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="footer">
          <button class="btn-reset">重置</button><button class="btn-sure">确认</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
let that;
import { getTrader } from "@/api/user";
export default {
  name: "",
  data() {
    return {
      page: 1,
      limit: 20,
      show: false,
      showPicker: false, // 时间选择
      list: [],
      loading: true,
      finished: false,
    };
  },
  filters: {
    fometCatePram: (value) => {
      console.log(this);
      let str = "";
      switch (value) {
        case "0":
          str = that.$t("m_fundsSummary.sr");
          break;
        case "1":
          str = that.$t("m_fundsSummary.zc");
          break;
        case "2":
          str = that.$t("m_fundsSummary.edzh");
          break;
        case "3":
          str = that.$t("m_fundsSummary.xgktye");
          break;
        default:
          break;
      }
      return str;
    },
    fometTypePram: (value) => {
      console.log(value + "===========");
      let str = "";
      switch (value) {
        case "0":
          str = that.$t("m_fundsSummary.hdsy");
          break;
        case "1":
          str = that.$t("m_fundsSummary.xtck");
          break;
        case "2":
          str = that.$t("m_fundsSummary.tzcx");
          break;
        case "3":
          str = that.$t("m_fundsSummary.xtzjye");
          break;
        case "4":
          str = that.$t("m_fundsSummary.jjck");
          break;
        case "5":
          str = that.$t("m_fundsSummary.sdjsktye");
          break;
        case "6":
          str = that.$t("m_fundsSummary.sdzjkeye");
          break;
        case "7":
          str = that.$t("m_history.tzsb");
          break;
        case "8":
          str = that.$t("m_fundsSummary.sdzjdml");
          break;
        case "9":
          str = that.$t("m_fundsSummary.sdzjdml");
          break;
        case "10":
          str = that.$t("m_fundsSummary.sdjsdml");
          break;
        case "11":
          str = that.$t("m_fundsSummary.tzmr");
          break;
        case "12":
          str = that.$t("m_fundsSummary.sdkk");
          break;
        case "13":
          str = that.$t("m_fundsSummary.xtkk");
          break;
        case "14":
          str = that.$t("m_fundsSummary.tkdsh");
          break;
        case "15":
          str = that.$t("m_fundsSummary.sdjsktye");
          break;
        case "16":
          str = that.$t("m_fundsSummary.sdzjkeye");
          break;
        case "17":
          str = that.$t("m_fundsSummary.txsb");
          break;
        default:
          break;
      }
      return str;
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    that = this;
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getTraderShow();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      console.log("抽屉");
      this.show = true;
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onloadInfo() {
      this.page += 1;
      this.getTraderShow();
    },
    /* 查询资金明细 */
    getTraderShow() {
      getTrader({
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        console.log(res);
        if (res.code + "" === "200") {
          let result = res.data;
          if (result.length > 0) {
            result.forEach((element) => {
              this.list.push(element);
            });
            this.loading = false;
          } else {
            // 加载状态结束
            this.loading = false;
            this.finished = true;
          }
        } else {
          // 加载状态结束
          this.loading = false;
          this.finished = true;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  /* background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important; */
  background-color: rgb(50, 59, 92) !important;
}

/deep/.van-nav-bar__title {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-nav-bar .van-icon {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

.van-popup {
  background-color: #524f45;
}

#UserFunds {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #303e70 !important;

  .funds_warp {
    position: absolute;
    top: 0.88rem;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 0;

    .funds_list {
      width: 100%;
      padding: 0 0.25rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #2b2e43 !important;

      li {
        height: 1.2rem;
        border-bottom: 0.01rem solid #333333;
        padding: 0.19rem;
        box-sizing: border-box;

        p {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;

          strong {
            font-size: 0.28rem;
            color: #ffffff;
            line-height: 0.42rem;
            font-weight: 500;
          }

          em {
            font-size: 0.3rem;
            color: #9fb0ff;
            line-height: 0.42rem;
            font-weight: 500;
          }

          span {
            font-size: 0.24rem;
            color: #ffffff;
            line-height: 0.42rem;
          }
        }
      }
    }
  }
}
</style>